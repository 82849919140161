import React from 'react'
import styled from 'styled-components'
import { FlexDiv } from '../design-system/FlexDiv'

const Pages = styled.h1`
  font-family: CentraNo2-Book;
  font-size: 45px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  // line-height: 2.44;
  letter-spacing: -0.68px;
  text-align: left;
  color: #ffffff;
  margin-right: 16px;
`

const Rectangle = styled.div`
  width: 27.5em;
  height: 25em;
  opacity: 0.9;
  background-color: #1c1c1c;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 150px;
  display: flex;
  flex-direction: column;
  padding: 0 35px;
`

const Info = styled.div`
  margin: 0px;
  color: white;
  display: flex;
  // background: red;
  justify-content: space-between;
`

const NoPages = styled.h1`
  font-family: CentraNo2-thin;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  // line-height: 4.58;
  letter-spacing: -0.36px;
  text-align: left;
  color: #adadad;
`

const Arrows = styled.div`
  margin-top: 10%;
  // margin-left: 50px;
`

const OurHistoryWrapper = styled.div`
  max-width: 90%;
  margin: auto;
  padding: 3rem 5rem 2rem;
  display: inline-flex;
`

const OurHistoryTitle = styled.h3`
  font-family: CentraNo2-Bold;
  margin: 0 1em;
`

const OurHistoryText = styled.p`
  font-family: CentraNo2-Book;
  font-size: 0.9em;
  line-height: 1.5em;
  margin: 2em 1em;
`

export const RichmondHillCommunityGeo: React.FunctionComponent = () => {
  return (
    <OurHistoryWrapper className="commGeoWrapper">
      <FlexDiv style={{ flexFlow: 'column' }}>
        <FlexDiv>
          <OurHistoryTitle>Geography</OurHistoryTitle>
        </FlexDiv>
        <FlexDiv className="mobileColumn">
          <OurHistoryText style={{ flex: 1 }}>
            Richmond Hill is bounded by Bloomington Rd. (Aurora town limit) to
            the north, York Regional Road 7 (Vaughan city limit west of Yonge
            St., Markham city limit east of Yonge St.) to the south, Bathurst
            St. (Vaughan city limit) to the west, and Ontario Highway 404
            (Markham city limit) to the east. Its southern limit is about 4
            kilometres (3 miles) north of the Toronto city limit, Steeles
            Avenue, and approximately 20 kilometres (12 miles) north of the
            downtown core of Toronto. The city extends 14 kilometres (9 miles)
            north-south and 7 kilometres (3.5 miles) east-west.
          </OurHistoryText>
          <OurHistoryText style={{ flex: 1 }}>
            The city itself is at a much greater elevation than other
            communities within the Greater Toronto Area (GTA) and with exclusion
            of areas closely alongside its borders, the constituent of York
            region with the greatest height above sea level. The uneven melting
            of the glacier that formed the city has led to an extremely varied
            geography within the city's own borders, leading to such features as
            kettle lakes, minor tributaries and most notably the Oak Ridges
            Moraine. The kettle lakes are predominantly confined to the northern
            fringes of the city.
          </OurHistoryText>
          <OurHistoryText style={{ flex: 1 }}>
            Being elevated above the surrounding region, precipitation in the
            city tends to flow outwards via the multitude of streams and rivers
            that flow through the city. Principally water flow is in one of two
            directions, south to Lake Ontario or north to Lake Simcoe where they
            join up with larger tributaries that feed into the water bodies. Of
            note, are the Rouge, Humber, and Don rivers which receive some of
            their flow from sources in the city. There has been a considerable
            amount of pressure applied to the government to shhield the area
            from development.
          </OurHistoryText>
        </FlexDiv>
      </FlexDiv>
    </OurHistoryWrapper>
  )
}
