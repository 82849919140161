import React from 'react'
import { EllicotLayout } from 'components/EllicotLayout'
import { RichmondHillCommunityHistory } from 'components/RichmondHillCommunityHistory'
import { RichmondHillCommunityGeo } from 'components/RichmondHillCommunityGeo'
import { OurPromise } from 'components/OurPromise'
import { GivesBack } from 'components/GivesBack'
import { RichmondHillCommunityHero } from 'components/RichmondHillCommunityHero'
import { RichmondHillCommunityInformation } from 'components/RichmondHillCommunityInformation'
import { CommunitySearch } from 'components/CommunitySearchRichmondHill'
import { GetListingsOptionsType } from '@services/repliers/get-listings.service'
import { GetListingsType } from '@services/repliers/repliers-services.type'
import { services } from '@services'

const RichmondHill: React.FunctionComponent = () => {
  return (
    <EllicotLayout>
      <RichmondHillCommunityHero />
      <RichmondHillCommunityHistory />
      <RichmondHillCommunityInformation />
      <RichmondHillCommunityGeo />
      <CommunitySearch />
    </EllicotLayout>
  )
}

export default RichmondHill
