import React from 'react'
import styled from 'styled-components'
import { FlexDiv } from '../design-system/FlexDiv'

const Pages = styled.h1`
  font-family: CentraNo2-Book;
  font-size: 45px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  // line-height: 2.44;
  letter-spacing: -0.68px;
  text-align: left;
  color: #ffffff;
  margin-right: 16px;
`

const Rectangle = styled.div`
  width: 27.5em;
  height: 25em;
  opacity: 0.9;
  background-color: #1c1c1c;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 150px;
  display: flex;
  flex-direction: column;
  padding: 0 35px;
`

const Info = styled.div`
  margin: 0px;
  color: white;
  display: flex;
  // background: red;
  justify-content: space-between;
`

const NoPages = styled.h1`
  font-family: CentraNo2-thin;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  // line-height: 4.58;
  letter-spacing: -0.36px;
  text-align: left;
  color: #adadad;
`

const Arrows = styled.div`
  margin-top: 10%;
  // margin-left: 50px;
`

const OurHistoryWrapper = styled.div`
  max-width: 90%;
  margin: auto;
  padding: 5rem 5rem 2rem;
  display: inline-flex;
`

const OurHistoryTitle = styled.h3`
  font-family: CentraNo2-Bold;
  margin: 0 1em;
`

const OurHistoryText = styled.p`
  font-family: CentraNo2-Book;
  font-size: 0.9em;
  line-height: 1.5em;
  margin: 2em 1em;
`

export const RichmondHillCommunityHistory: React.FunctionComponent = () => {
  return (
    <OurHistoryWrapper className="ourHistoryWrapper">
      <FlexDiv style={{ flexFlow: 'column' }}>
        <FlexDiv>
          <OurHistoryTitle>Histortical Background</OurHistoryTitle>
        </FlexDiv>
        <FlexDiv className="mobileColumn">
          <OurHistoryText style={{ flex: 1 }}>
            The village of Richmond Hill was incorporated by a bylaw of the York
            County Council on June 18, 1872, coming into effect January 1,
            1873.In September 1956, the Ontario Municipal Board approved its
            elevation to Town status, effective January 1, 1957. Yonge Street
            through Richmond Hill expanded from two lanes to four in 1971,
            relieving congestion on what was known as "Ontario's worst stretch
            of highway". GO train service was extended to Richmond Hill on April
            29, 1978 by Bill Davis.
          </OurHistoryText>
          <OurHistoryText style={{ flex: 1 }}>
            When the new council convened in 1980, led by new mayor Al Duffy,
            the town remained without a development plan. The council hired
            civic planner Peter Walker to produce a new official plan. By
            September 1981, the new plan was drafted, with limited development
            of northern Richmond Hill, industrial development centred in the
            south-east part of town and commercial centres remaining along Yonge
            Street. The plan was approved in July 1982 by the Ontario Municipal
            Board.
          </OurHistoryText>
          <OurHistoryText style={{ flex: 1 }}>
            Richmond Hill's growth continued during the 1990s, fueled in
            significant part by immigration, with many businesses opened on
            Highway 7 around Leslie Street serving the new Chinese Community
            that formed there. In the early 90s, Statistics Canada named
            Richmond Hill as Canada's fastest-growing community. On March 25,
            2019, the Richmond Hill Council passed a motion to change the title
            of Richmond Hill from 'town' to 'city'.
          </OurHistoryText>
        </FlexDiv>
      </FlexDiv>
    </OurHistoryWrapper>
  )
}
