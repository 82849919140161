import React from 'react'
import styled from 'styled-components'
import bgimg from '../images/richmondHillHero.jpg'
import { FlexDiv } from '../design-system/FlexDiv'

const Pages = styled.h1`
  font-family: CentraNo2-Book;
  font-size: 45px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  // line-height: 2.44;
  letter-spacing: -0.68px;
  text-align: left;
  color: #ffffff;
  margin-right: 16px;
`

const Rectangle = styled.div`
  width: 22.5em;
  background-color: #1c1c1c;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 150px;
  display: flex;
  flex-direction: column;
  padding: 35px;
  margin-right: 10%;
`

const Info = styled.div`
  margin: 0px;
  color: white;
  display: flex;
  // background: red;
  justify-content: space-between;
`

const NoPages = styled.h1`
  font-family: CentraNo2-thin;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  // line-height: 4.58;
  letter-spacing: -0.36px;
  text-align: left;
  color: #adadad;
`

const Arrows = styled.div`
  margin-top: 10%;
  // margin-left: 50px;
`

const CityTitle = styled.h4`
  font-family: CentraNo2-Book;
  font-size: 1.5em;
  color: #fff;
  margin: 0 0 0.5em;
`
const DevelopmentTitle = styled.h2`
  font-family: CentraNo2-Medium;
  font-size: 2em;
  color: #fff;
  margin: 0 0 0.5em;
`

const DevelopmentExcerpt = styled.p`
  font-family: CentraNo2-Book;
  font-size: 0.9em;
  color: #fff;
  margin: 0 0 0.5em;
  line-height: 1.5;
`

const Button = styled.button`
  width: 27.0625em;
  height: 3.5625em;
  border-radius: 0.1875em;
  background-color: #ffffff;
  font-family: CentraNo2-Medium;
  font-size: 1em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: 0.05em;
  color: #1c1c1c;
`

export const RichmondHillCommunityHero: React.FunctionComponent = () => {
  return (
    <FlexDiv>
      <FlexDiv
        style={{
          width: '100%',
          height: '75vh',
          backgroundPosition: 'bottom',
          backgroundImage: 'url(' + bgimg + ')',
          objectFit: 'initial',
          position: 'unset',
          backgroundSize: 'cover',
          justifyContent: 'flex-end',
        }}
        className="communityHeroWrapper"
      >
        <Rectangle className="desktopOnly">
          <div>
            <Info>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <h1 style={{ marginTop: '0.25em' }}>Richmond Hill</h1>
              </div>
            </Info>
            <DevelopmentExcerpt>
              is a city in south-central York Region, Ontario, Canada. Part of
              the Greater Toronto Area, it is the York Region's third most
              populous municipality and the 28th most populous municipality in
              Canada. Richmond Hill has seen significant population growth since
              the 1990s. The city is home to the David Dunlap Observatory
              telescope, the largest in Canada.
            </DevelopmentExcerpt>
            <Button
              type="submit"
              value="Watch Neighbourhood Properties"
              style={{
                width: '27.5em',
                borderRadius: 0,
                border: '1px solid #fff',
                background: 'transparent',
                height: 'auto',
                padding: '15px 20px',
                fontSize: '0.7em',
                textTransform: 'uppercase',
                color: '#fff',
                marginTop: '3em',
                letterSpacing: '1px',
              }}
            >
              WATCH NEIGHBORHOOD PROPERTIES
            </Button>
          </div>
        </Rectangle>
      </FlexDiv>
      <Rectangle className="mobileOnlyOD mobileRectangle">
        <div>
          <Info>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <h1 style={{ marginTop: '0.25em' }}>Richmond Hill</h1>
            </div>
          </Info>
          <DevelopmentExcerpt>
            is a city in south-central York Region, Ontario, Canada. Part of the
            Greater Toronto Area, it is the York Region's third most populous
            municipality and the 28th most populous municipality in Canada.
            Richmond Hill has seen significant population growth since the
            1990s. The city is home to the David Dunlap Observatory telescope,
            the largest in Canada.
          </DevelopmentExcerpt>
          <Button
            type="submit"
            value="Watch Neighbourhood Properties"
            style={{
              width: '27.5em',
              borderRadius: 0,
              border: '1px solid #fff',
              background: 'transparent',
              height: 'auto',
              padding: '15px 20px',
              fontSize: '0.7em',
              textTransform: 'uppercase',
              color: '#fff',
              marginTop: '3em',
              letterSpacing: '1px',
            }}
          >
            WATCH NEIGHBORHOOD PROPERTIES
          </Button>
        </div>
      </Rectangle>
    </FlexDiv>
  )
}
