import React from 'react'
import styled from 'styled-components'
import { FlexDiv } from '../design-system/FlexDiv'
import oakridges from '../images/oakridges.jpg'

const Pages = styled.h1`
  font-family: CentraNo2-Book;
  font-size: 45px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  // line-height: 2.44;
  letter-spacing: -0.68px;
  text-align: left;
  color: #ffffff;
  margin-right: 16px;
`

const Rectangle = styled.div`
  width: 27.5em;
  height: 25em;
  opacity: 0.9;
  background-color: #1c1c1c;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 150px;
  display: flex;
  flex-direction: column;
  padding: 0 35px;
`

const Info = styled.div`
  margin: 0px;
  color: white;
  display: flex;
  // background: red;
  justify-content: space-between;
`

const NoPages = styled.h1`
  font-family: CentraNo2-thin;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  // line-height: 4.58;
  letter-spacing: -0.36px;
  text-align: left;
  color: #adadad;
`

const Arrows = styled.div`
  margin-top: 10%;
  // margin-left: 50px;
`

const OurHistoryWrapper = styled.div`
  max-width: 90%;
  margin: auto;
  padding: 2rem 5rem;
  display: inline-flex;
`

const OurHistoryTitle = styled.h3`
  font-family: CentraNo2-Bold;
`

const OurHistoryText = styled.p`
  font-family: CentraNo2-Book;
  font-size: 0.9em;
  line-height: 1.5;
`

export const RichmondHillCommunityInformation: React.FunctionComponent = () => {
  return (
    <OurHistoryWrapper className="ourHistoryWrapper">
      <FlexDiv style={{ flexFlow: 'column' }}>
        <FlexDiv className="informationImgWrapper">
          <FlexDiv style={{ flex: 1 }}>
            <div>
              <img
                src={oakridges}
                style={{ width: '90%', height: '90%', objectFit: 'contain' }}
              />
            </div>
          </FlexDiv>
          <FlexDiv
            style={{
              flex: 2,
              flexDirection: 'column',
              placeSelf: 'flex-start',
            }}
          >
            <OurHistoryTitle style={{ marginTop: 0 }}>
              Education & Government
            </OurHistoryTitle>
            <FlexDiv>
              <OurHistoryText>
                The York Region District School Board operates 27 public
                elementary schools in Richmond Hill, as well as 5 secondary
                schools: Alexander Mackenzie High School, Bayview Secondary
                School, Langstaff Secondary School, Richmond Green Secondary
                School and Richmond Hill High School.Students in schools in the
                York Region District School Board have scored above the
                provincial average on the Assessment of Reading, Writing and
                Mathematics, Primary Division (Grades 1–3) and Junior Division
                (Grades 4–6) since their introduction in 2002. The board's
                students in academic math streams have performed above the
                provincial average on the Grade 9 Assessment of Mathematics
                every year since its inception in 2002, while those in applied
                math streams were below the provincial average in 2002–2005, and
                above the provincial average from 2005–2007. Post-secondary
                education services are provided to the residents of Richmond
                Hill by several post-secondary educational institutions in
                Toronto, some of which have satellite campuses in nearby
                communities.
              </OurHistoryText>
            </FlexDiv>
            <OurHistoryText style={{ flex: 1 }}>
              The York Catholic District School Board operates 13 Catholic
              elementary schools in Richmond Hill. It also operates two Catholic
              secondary schools, St. Theresa of Lisieux Catholic High School and
              Jean Vanier Catholic High School. There are also four private
              primary schools located in Richmond Hill and five private
              secondary schools, including Holy Trinity School, Richmond Hill
              Christian Academy, Discovery Academy, Toronto Montessori Schools,
              and Richmond Hill Montessori & Private Elementary School.
            </OurHistoryText>
          </FlexDiv>
        </FlexDiv>
      </FlexDiv>
    </OurHistoryWrapper>
  )
}
